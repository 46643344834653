import {Form} from "../models/form_model";
import {
    ChangeEvent,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import TextareaAutosize from 'react-textarea-autosize';

interface CardProps extends PropsWithChildren {
    formItem: Form
    updateParentForms: Dispatch<SetStateAction<Form[]>>
}

export default function Card({formItem, updateParentForms}: CardProps) {
    const [form, setForm] = useState<Form>(formItem)
    const {textBody, isSelected} = formItem
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => setForm(({id}) => new Form(id, textBody, isSelected)), [textBody, isSelected])

    const setParentForms = useCallback(({id, textBody, isSelected}: Form) => {
        updateParentForms((forms) => {
            let foundForm: Form | undefined = forms.find(f => f.id === id);

            if (foundForm) {
                foundForm.textBody = textBody
                foundForm.isSelected = isSelected
            }
            return forms;
        })
    }, [])

    const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        const {value} = e.target
        form.textBody = value
        setForm(({id, isSelected}): Form => {
            const form = new Form(id, value, isSelected)
            setParentForms(form)
            return form
        })
    }, [])

    const onCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setForm(({id, textBody}) => {
            const form = new Form(id, textBody, e.target.checked)
            setParentForms(form)
            return form
        })
    }, [])

    const onButtonClickCopy = () => {
        navigator.clipboard.writeText(`${form.textBody}`)
    }

    return (
        <div className="is-flex is-justify-content-center is-align-content-center m-2">
            <div>

                <input className="ml-4" type="checkbox" checked={form.isSelected} onChange={onCheckboxChange}
                       id={`is-selected-${formItem.id}`} style={{width: "2rem", height: "2rem"}}/>
            </div>
            <button className="mx-4 button is-primary is-outlined" onClick={onButtonClickCopy}>copy</button>
            <TextareaAutosize minRows={2} ref={textareaRef} cols={form.textareaCols} rows={form.textareaRows}
                              value={form.textBody} onChange={onInputChange}/>
        </div>
    )
}