import {ChangeEvent, MouseEvent, PropsWithChildren, useRef, useState} from "react";

export interface ConvertState {
    current: string
    converted: number
}

interface Props extends PropsWithChildren {
    onInputChangeProp: (e: ChangeEvent<HTMLInputElement>) => ConvertState
    onCopyButtonClick: (state: ConvertState) => string
    getResult: (state: ConvertState) => string
    headerUnit: string
    fromUnitText: string
    getFormatedResult: (state: ConvertState) => string
}

export default function UnitConverter({
                                          onInputChangeProp,
                                          onCopyButtonClick,
                                          getResult,
                                          headerUnit,
                                          fromUnitText,
                                          getFormatedResult
                                      }: Props) {
    const [value, setValue] = useState<ConvertState>({current: '0', converted: 0})
    const formRef = useRef<HTMLFormElement>(null);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const {current, converted} = onInputChangeProp(e)
        setValue(() => ({current, converted}))
    }

    const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        navigator.clipboard.writeText(onCopyButtonClick(value))
    }

    const onInputClick = () => {
        setValue(() => ({current: '0', converted: 0}))
    }

    return (
        <form className="mx-4 mb-5" ref={formRef} noValidate={true}>
            <h1>{headerUnit}</h1>
            <div className="mb-2">
                <div className="is-flex is-align-content-space-between">
                    <input className="input is-large" type="number" value={value.current} style={{width: "20rem"}}
                           onChange={onInputChange} onClick={onInputClick}/>
                    <span className="has-text-weight-medium m-4">{fromUnitText}</span>
                </div>
                <div className="is-size-3">{getResult(value)}</div>
                {getFormatedResult(value) !== '' ? <pre>{getFormatedResult(value)}</pre> : null}
            </div>
            <button onClick={onButtonClick} className="button is-info" style={{width: "10rem"}}>copy</button>
        </form>
    )
}