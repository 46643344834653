import {useEffect, useState} from "react";
import {Form} from "../models/form_model";
import Card from "./card";


export default function List() {
    const [forms, setForms] = useState<Form[]>([])

    useEffect(() => {
        let storedInputs: string | null = localStorage.getItem("forms");

        if (storedInputs == null) return setForms([new Form(1, "", false)])

        setForms(JSON.parse(storedInputs) as Form[])
    }, [])

    function addForm() {
        setForms((forms) => {
            const newForm = new Form(0, "", false)
            if (forms.length > 0) {
                const {id} = forms[forms.length - 1]
                newForm.id = id + 1
            }
            return [...forms, newForm]
        })
    }

    function removeForm() {
        setForms((forms) => forms.slice(0, forms.length - 1))
    }

    function resetList() {
        setForms([new Form(1, "", false)])
    }

    function saveForms() {
        const stringifiedForms = JSON.stringify(forms)
        localStorage.setItem("forms", stringifiedForms)
    }

    function copyAllSelected() {
        let allSelectedForms: string[] = forms.filter(form => form.isSelected).map(({textBody}) => textBody);
        navigator.clipboard.writeText(allSelectedForms.join('\n'))
    }

    return (<div className="column is-half">
        <div>
            <div >
                {
                    forms.map((input) =>
                        <Card key={input.id} formItem={input} updateParentForms={setForms}/>)
                }
            </div>
            <div className="is-flex is-justify-content-space-around">
                <button className="button is-primary" onClick={addForm}>Add</button>
                <button className="button is-warning" onClick={removeForm}>Remove</button>
                <button className="button is-danger" onClick={resetList}>Reset All</button>
                <button className="button is-primary" onClick={saveForms}>save</button>
                <button className="button is-primary" onClick={copyAllSelected}>copy-all-selected</button>
            </div>
        </div>
    </div>)
}