import {ChangeEvent} from 'react'
import List from './components/list'
import UnitConverter, {ConvertState} from './components/unitConverter'
import 'bulma/css/bulma.css'

const kgToPoundInputChangeProp = (e: ChangeEvent<HTMLInputElement>): ConvertState => {
    let value: string = e.target.value
    const parsedInput: number = parseFloat(value.length === 0 ? '0' : value)
    const convertedValue = !parsedInput ? 0 : parsedInput * 2.2
    return {current: value, converted: convertedValue}
}

const umolToMgCopyButtonClickProp = ({converted}: ConvertState): string => {
    return `${converted.toFixed(2)} mg/dL`
}

const umolToMgInputChangeProp = (e: ChangeEvent<HTMLInputElement>): ConvertState => {
    let value: string = e.target.value
    const parsedInput: number = parseFloat(value.length === 0 ? '0' : value)
    const convertedValue = !parsedInput ? 0 : parsedInput / 88.42
    return {current: value, converted: convertedValue}
}

const kgToPoundCopyButtonClickProp = ({converted}: ConvertState): string => {
    return `${converted.toFixed(2)}`
}


const rbsInputChangeProp = (e: ChangeEvent<HTMLInputElement>): ConvertState => {
    let value: string = e.target.value
    const parsedInput: number = parseFloat(value.length === 0 ? '0' : value)
    const convertedValue = !parsedInput ? 0 : parsedInput * 18.02
    return {current: value, converted: convertedValue}
}

const rbsFormatedResult = ({
                               current,
                               converted
                           }: ConvertState) => `Random Blood Sugar: ${current !== '0' && current.at(0) === '0' ? current.slice(1) : current} mmol/L (${converted.toFixed(2)} mg/dL)\nNon-fasting specimen`

const rbsCopyButtonClickProp = (state: ConvertState): string => {
    return rbsFormatedResult(state)
}

function App() {
    return (
        <div className="columns mt-1">
            <List/>
            <div className={'column'}>
                <UnitConverter onInputChangeProp={kgToPoundInputChangeProp}
                               onCopyButtonClick={kgToPoundCopyButtonClickProp}
                               getResult={({converted}) => `${converted.toFixed(2)} lbs`}
                               headerUnit={'KG to LB/Pounds'}
                               fromUnitText={'kg'}
                               getFormatedResult={() => ''}
                />
                <UnitConverter onInputChangeProp={umolToMgInputChangeProp}
                               onCopyButtonClick={umolToMgCopyButtonClickProp}
                               getResult={({converted}) => `${converted.toFixed(2)} mg/dL`}
                               headerUnit={'Creatinine'}
                               fromUnitText={'umol/L'}
                               getFormatedResult={() => ''}
                />
                <UnitConverter onInputChangeProp={rbsInputChangeProp}
                               onCopyButtonClick={rbsCopyButtonClickProp}
                               getResult={({converted}) => `${converted.toFixed(2)} mg/dL`}
                               headerUnit={'Random Blood Sugar'}
                               fromUnitText={'mmol/L'}
                               getFormatedResult={
                                   rbsFormatedResult}
                />
            </div>

        </div>
    )
}

export default App
